<template>
  <div>
    <section class="cover -no-padding">
      <b-card class="card-item text-center">
        <b-img
            :src="require('@/assets/images/pages/guideline/guideline-1.webp')"
            class="img-fluid" />
      </b-card>
    </section>
    <section class="caption">
      <b-card class="card-item -caption text-center">
        <b-card-text class="card-item__text-wrap">
          <h2 class="section-title">Using these guidelines</h2>
          <div class="card-item__content">
            <p>These guidelines have been prepared to help anyone involved in 
designing or preparing printed or digital materials for IOT IELTS Online 
Tests. They cover the key facts, how we communicate and how the 
various elements of the brand work together.</p>
            <p>
              It is important that everything we do has a consistent look and feel to it, 
as this reinforces our ‘brand’ and helps to spread and support general 
awareness of who we are and what we do
            </p>
            <p>
              The guidelines are not intended to be ‘set in stone’, but to be used as 
the starting point and inspiration when developing any communications.
            </p>
            <p>
              We are all responsible for building and maintaining our brand integrity!
            </p>
          </div>
        </b-card-text>
      </b-card>
    </section>
    <section class="what-is">
      <b-card class="card-item -what-is text-center">
        <b-card-text class="card-item__text-wrap">
          <h2 class="section-title">What is branding?</h2>
          <div class="card-item__content">
            <p>Branding is a way of clearly highlighting what makes your product 
or service different to, and more attractive than, your competitors.</p>
            <p>
              The intelligent use of design, advertising, marketing, service 
proposition, and corporate culture can all help to generate 
associations in people’s minds that will benefit your business.
            </p>
          </div>
        </b-card-text>
      </b-card>
    </section>
    <section class="we-do">
      <b-card class="card-item -we-do text-center">
        <b-card-text class="card-item__text-wrap">
          <h2 class="section-title">What we do</h2>
          <div class="card-item__content">
            <p>Do you remember a time in your life when somebody helped you learning 
English, making you smile and really enjoy it? This is what we do with IOT 
IELTS Online Tests. We provide a free platform where the digital generation 
can practice real IELTS tests, follow up their target score, easy and unique tests 
reviews and explanations, interact with other students and succed in their 
foreign universities aplications.</p>
            <p>
              An international education in English is one of the most common dreams of 
the young generation since it can bring better future careers oportunities, and 
InterGreat is here to help.
            </p>
          </div>
        </b-card-text>
      </b-card>
    </section>
    <section class="we-are">
      <b-card class="card-item -we-are text-center">
        <b-card-text class="card-item__text-wrap">
          <h2 class="section-title">Who we are</h2>
          <div class="card-item__content">
            <p>IELTS Online Tests is the biggest platform for IELTS globally 
with over 1.3 million tests taken every month. It was initially 
developed by the Vietnamese programmer and IELTS student 
Danny Nguyen who dedicated years of his life developing a free 
tool to help new IELTS students to achieve their target score just 
as himself struggled to achieve. He programmed this amazing 
system and created such a huge community of participative 
international students, that caught the attention of the InterGreat 
Education Group, a leading enterprise that represents the British 
Universities all around the world. After they joining hands, a 
fresh new website was designed so we can now reach a even 
bigger audience and help even more people!
</p>
          </div>
        </b-card-text>
      </b-card>
    </section>
    <section class="why-how">
      <h2 class="section-title">Why and How</h2>
      <b-row>
        <b-col md="4">
          <b-card class="why-how-item text-center">
            <b-card-text class="why-how-item__text-wrap">
              <h3 class="why-how-item__sm-title">Why?</h3>
              <div class="why-how-item__content">
                <p>Here to give power to young 
international students to shape 
their own future.</p>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="why-how-item text-center">
            <b-card-text class="why-how-item__text-wrap">
              <h3 class="why-how-item__sm-title">How?</h3>
              <div class="why-how-item__content">
                <p>We give free and user friendly 
webproducts so students can 
interact with each other and 
pass IETLS exams easily.</p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="8">
          <b-card class="why-how-item text-center">
            <b-card-text class="why-how-item__text-wrap">
              <h3 class="why-how-item__sm-title">We are flexible and human in scale, 
despite the large numbers we make</h3>
              <div class="why-how-item__content">
                <p>Our professionalism and the thoughtful insights we gain from our students 
community is applied consistenly and efficiently</p>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="why-how-item text-center">
            <b-card-text class="why-how-item__text-wrap">
              <h3 class="why-how-item__sm-title">True to the inspiration of Danny 
Nguyen</h3>
              <div class="why-how-item__content">
                <p>We aim to keep it free, lead by IOT’s website’s users needs. InterGreat Education 
Group is determined to provide everything necessary to keep improving this high 
end education tool in a way everyone who relates to it, will win.</p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <section class="our-value text-center">
      <h2 class="section-title">Our Values</h2>
      <b-row>
        <b-col md="4">
          <b-card class="our-value-item text-center">
            <b-card-text class="our-value-item__text-wrap">
              <h3 class="our-value-item__sm-title">Creative</h3>
              <div class="our-value-item__content">
                <p>The on-the-ground knowledge and years of 
experience to develop solutions that work in 
the real world. Innovative and empath where 
a new solution or approach is needed.
</p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="our-value-item text-center">
            <b-card-text class="our-value-item__text-wrap">
              <h3 class="our-value-item__sm-title">Connected</h3>
              <div class="our-value-item__content">
                <p>Working directly with British universities 
(main interest of IELTS takers), international 
students, and front line workers around the 
world who understand the problems and are 
aware of what the internet needs.</p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="our-value-item text-center">
            <b-card-text class="our-value-item__text-wrap">
              <h3 class="our-value-item__sm-title">Expert</h3>
              <div class="our-value-item__content">
                <p>Can be trusted by British universities and 
investidors for education expertise and 
the high quality International students we 
provide.</p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
  import { BImg, BCard, BAlert, BLink, BCardText, BRow, BCol } from 'bootstrap-vue'
  export default {
    components: {
      BImg,
      BCard,
      BAlert,
      BLink,
      BRow,
      BCol,
      BCardText
    },
  }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-about.scss';
</style>